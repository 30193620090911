/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@use 'src/buttons';

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// ******************************
// FONT
// ******************************
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;450;500;550;600;650;700;750;800&display=swap');

// ******************************
// TEXT AREA
// ******************************
@import 'src/app/components/forms/text-area/text-area.component';
// ******************************
// FORM TOGGLE
// ******************************
@import 'src/app/components/forms/segment-toggle/segment-toggle.component.scss';
// ******************************
// ACTION SHEET
// ******************************
@import 'src/app/components/forms/action-sheet/action-sheet.component.scss';

@import './app/app.component.scss';

:root { color-scheme: light }

body {
  background-color: #ffffff;
}

.in-app-notification-alert {
  --background: white;
  --color: var(--primary);
}

.in-app-notification-alert-dismiss {
  --background: var(--secondary);
  --color: var(--primary);
}

.in-app-notification-alert-goto {
  --background: var(--primary);
  --color: white;
}

.in-app-notification-toast {
  --background: purple;
  --color: white;
  --border-color: green;
  --border-width: 6px;
  --border-style: solid;
}

.in-app-notification-toast-SAMPLE {
  &::part(backdrop) {
    background-color: #000;
  }

  &::part(arrow) {
    --background: red;
  }

  &::part(content) {
    font-weight: 600;
    --background: red;
    padding: 50px;
  }
}

// ******************************
// GENERAL
// ******************************
* {
  font-family: 'Open Sans', sans-serif;
}

.spacer-bottom {
  padding-bottom: 40px;
}

.centered {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10%;
  margin-left: 10%;
}

.safe-area {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 20px;
}

// ******************* //
// Header
ion-header {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 20px;
}

ion-header ion-toolbar:first-of-type {
  --background: #fff;
  padding: 16px;
  color: #132A4E;
  border-bottom: 1px solid #C2C2C2;

  ion-title {
    font-size: 1.125rem;
  }

  ion-buttons {
    margin: 0;

    ion-button {
      margin: 0;
    }

    ion-button:hover {
      --background: #fff;
      --background-hover-opacity: 0;
    }

    ion-button::part(native) {
      padding: 0;
    }
  }
}

@media (width > 401px) {

  ion-header ion-toolbar:first-of-type {

    ion-title {
      font-size: 1.25rem;
    }
  }
}

ion-item.last-item {
  --inner-border-width: 0;
  --border-style: none !important;
}

// forms
// required fields
ion-item,
div#textarea-display {
  &.required {
    mm-provider-input {
      margin-bottom: 0 !important;
      top: 0;
      position: relative;
    }

    &.md > ::after {
      font-size: 4vw;
    }
  }
  &.required > ion-input::after,
  &.required > ion-textarea::after,
  &.required > ion-select::after {
    font-size: 10px;
    font-weight: 400;
  }
  &.required > ion-select::after {
    right: 3.5px;
  }
}

// android required fields
.md ion-item {
  &.required > ion-input::after,
  &.required > ion-textarea::after,
  &.required > ion-select::after {
    font-size: 4vw;
  }
}

ion-input#provider-name {
  --padding-start: 15px;
  --padding-top: 0;
  --padding-bottom: 0;
}

ion-modal {
  ion-backdrop {
    visibility: visible;
  }

  .modal-wrapper {
    width: 86%;
    height: 86%;
    margin: 7%;
    position: absolute;
    border-radius: 13px;
    overflow: hidden;
  }
}

ion-popover {
  --width: 350px;
  --backdrop-opacity: 0.75;
}

ion-card#settings {
  padding: 20px;
  margin: 20px;
  height: calc(100% - 38px);

  ion-list {
    ion-item {
      margin-bottom: 30px;

      ion-text {
        ion-label {
          margin-bottom: 10px;
          font-weight: bold;
        }
      }
    }
  }
}

ion-action-sheet {
  &.related-injury-action-sheet {
    .action-sheet-button {
      font-size: 4vw;
    }
  }
}

.treatment-reminder::part(container) {
  flex-direction: column;
}

.searchbar-input.sc-ion-searchbar-ios {
  font-size: 4vw;
}

.searchbar-input.sc-ion-searchbar-md {
  font-size: 3.5vw;
}

ion-button {
  box-shadow: none;
  --box-shadow: none;

  &.submit-button {
    @include buttons.primary;
    margin-left: 0;
    margin-right: 0;
    --padding-end: 0;
    --padding-start: 0;
    width: 100%;
  }

  &.pain-level-previous-button, &.survey-previous-button, &.secondary-button {
    @include buttons.secondary;
  }

  &.pain-level-next-button, &.survey-next-button {
    @include buttons.primary;
  }

  &.tertiary-button {
    @include buttons.tertiary;
  }
}

ion-footer.footer-toolbar-padding ion-toolbar:last-of-type {
  padding: 0 16px 24px 16px;
  --border-style: none;
}

ion-footer, ion-footer.footer-toolbar-padding {
  text-align: right;
  border: none;
  box-shadow: none !important;

  & ion-toolbar {
    width: 100%;
    min-width: 340px;
    padding-bottom: 0;
    padding-block: 24px;
    padding-inline: 16px;
    --background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
    border: none;

    ion-buttons:first-of-type {
      width: 100%;
      margin-inline-end: 8px;

      ion-button {
        width: 100%;
        margin-inline: 0;
      }
    }

    ion-buttons:last-of-type {
      width: 100%;
      margin-inline-start: 8px;

      ion-button {
        width: 100%;
        margin-inline: 0;
      }
    }

    mm-save-button {
      flex-grow: 1;
    }


  }
}

.welcome-alerts .alert-wrapper {
  border-radius: 12px;
  padding: 0.5em;

  .alert-head {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 25px;
    margin-right: 7px;
  }

  .alert-title.sc-ion-alert-md,
  .alert-title.sc-ion-alert-ios {
    font-size: 16px;
    color: black;
    text-align: center;
  }

  .alert-message.sc-ion-alert-md,
  .alert-message.sc-ion-alert-ios {
    margin-top: 1em;
    color: black;
    text-align: center;
  }

  .alert-button.sc-ion-alert-md,
  .alert-button.sc-ion-alert-ios {
    color: white;
    font-weight: bold;
    background-color: var(--ion-color-primary);
    width: 100%;
    border-radius: 4px;
    margin: 0.5em;

    span {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
}

.textarea-wrapper {
  height: 100% !important;
}

.self-guided-details, app-weekly-health-check-in {
  .textarea-wrapper {
    min-height: 94px;
    padding-bottom: 16px;

    &-inner {
      height: 100%;
    };
  }

  textarea {
    --padding-top: 16px;
    --padding-bottom: 0;

    &::placeholder {
      font-size: 16px;
      font-style: normal;
      font-weight: 650;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

.successful-uploads mm-document-preview {
  ion-img {
    height: 48px;
    width: 40px;
    object-fit: cover;
  }
}

.form-mode-card {
  mm-document-preview {
    height: 48px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-row ion-text {
      height: 100%;
      width: 100%;

      #no-preview {
        display: flex !important;
        width: 100% !important;
        margin: 0 !important;
        border-radius: 0 !important;
        height: 100% !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: 10px;
      }
    }

    ion-img {
      top: 0;
    }
  }
}

mm-document-preview ion-row ion-text {
  ion-img {
    width: 100%;
  }
}

ion-tab-button {
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
}

ion-modal.alert-modal {
  --height: auto;
  --width: auto;
}

ion-modal.alert-modal::part(backdrop) {
  --background: rgba(0, 0, 0, 0.5);
}

ion-modal.alert-modal::part(content) {
  border-radius: 8px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

mm-segment-toggle {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px 0;
}

mm-input,
mm-select {
  width: 100%;
}

ion-modal.alert-modal::part(backdrop) {
  --background: rgba(0, 0, 0, 0.5);
}

/**/
/* global modal styles */
/**/
ion-modal {
  &::part(handle) {
    width: 70px;
    display: block;
  }

  &::part(content) {
    border-radius: 12px 12px 0 0;
  }
}

ion-modal.modal-without-border-radius {
  &::part(content) {
    border-radius: 0;
  }
}

ion-modal.pain-level-modal {
  ion-title {
    color: var(--ion-text-color);
  }

  .close-modal-button {
    color: var(--ion-text-color);
  }

  &::part(handle) {
    width: 70px;
    display: block;
  }

  &::part(content) {
    margin: 5px 5px;
    height: 99vh;
    border-radius: 0;
  }
}

ion-modal.full-screen {
  --min-width: 100%;
  --min-height: 100%;

  &::part(content) {
    border-radius: 0px;
  }
}

.bottom-sheet-modal {
  --height: auto;
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      color: $primary-text-color;
    }
  }
}

ion-content {
  ion-card-header {
    margin: 1em 1.5em;

    &.external-card-header {
      padding: 0;

      ion-card-title {
        text-transform: uppercase;
        color: #70757a;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        --color: #70757a;
      }
    }
  }
  ion-card {
    margin-bottom: 2em;

    ion-card-content {
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        color: #70757a;
        margin-bottom: 16px;

        &.ion-text-center {
          margin-top: 0px;
          padding-top: 0px;

          margin-bottom: 0px;
          padding-bottom: 0px;
        }
      }

      ion-text {
        ion-item:last-child {
          --border-style: none !important;
        }

        ion-grid {
          margin: 0px;
          padding: 0px;

          ion-col {
            margin: 0px;
            padding: 0px;

            ion-row {
              margin: 0px;
              padding: 0px;
            }
          }
        }
      }
    }
  }
}

mm-upload-documents-input {
  ion-card {
    width: 100% !important;
    box-shadow: none !important;

    ion-card-content {
      padding: 0 !important;
    }
  }
}

.mm-no-shadow {
  box-shadow: none;
  --box-shadow: none;
}

.header-md::after {
  background-image: none;
}

/* NEW ALERTS/ MESSAGES INDICATORS */
#new-message-indicator {
  top: 10px;
  position: absolute;
  right: 33px;
  color: #cc0000;
  width: 6.5px;
  height: 6.5px;

  ion-icon {
    height: 100%;
    width: 100%;
    display: flex;
  }
}

.md #new-message-indicator {
  right: 38px;
  top: 1.5vh;
}

.notifications #new-message-indicator {
  right: 0;
}

#no-legal-team-card {
  margin: 0;
  padding: 24px 24px 0;

  .banner-item {
    box-shadow: none;
    background-color: #f7f7f7;
    p {
      color: #132a4e;
    }

    .lottie-img-container {
      background: none;
    }
  }
}

.banner-item {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  ion-row {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ion-col {
      padding: 0px;
    }

    .banner-item-columns {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h1,
  h3 {
    font-size: 12px;
    margin: 0;
    color: #70757a;
    font-weight: 550;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  p {
    text-decoration: none;
    font-weight: 550;
    color: #115099;
    font-size: 14px;
    margin-bottom: 0;
  }

  .lottie-img-container {
    background: #ebf2fc;
    border-radius: 8px;
    margin-bottom: 0;
    height: 105px;
  }

  .img-container {
    background: #ebf2fc;
    border-radius: 8px;
    margin-bottom: 0;
    height: 105px;
    display: flex;
    align-items: center;
  }

  .linkObject {
    padding-top: 8px;
  }
}

.web-mode {
  ion-popover {
    --max-width: 480px !important;
    --width: auto !important;
    --height: auto;
    &::part(content) {
      left: 105px !important;
    }
  }
  mm-action-sheet {
    width: 480px !important;
    margin-left: auto;
    margin-right: auto;
  }
  mm-bottom-sheet {
    width: 480px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .mm-action-sheet {
    .ion-page {
      width: 480px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .date-time-input-box {
    min-width: 340px;
    max-width: 340px;
  }
}

/* SURVEY STARTS HERE */
// Code changes the position of the toast because
// margin doesn't work on the toast
.survey-toast {
  transform: translateY(-85px);
}

.sv-components-column--expandable {
  padding: 16px;
}

.survey-page-background-blue {
  --background: var(--ion-color-secondary) !important;
  .mm-survey-root {
    background: var(--ion-color-secondary) !important;
  }
  .background-overlay {
    background: var(--ion-color-secondary) !important;
  }
  mm-line-progress-bar {
    display: none !important;
  }
}

.sd-root-modern__wrapper {
  padding-bottom: 80px;
}

.mm-survey-root {
  background: #fff;
  overflow: visible !important;
  margin: 0;

  .mm-survey-page-body {
    padding: 0 16px;
  }

  .mm-survey-root-title,
  .mm-survey-header-title {
    display: none;
  }

  /** Code to change the headers and descriptions starts here **/
  .mm-survey-page-root {
    .sd-row.sd-page__row {
      &:nth-of-type(2) {
        margin-top: 0 !important;
      }
    }

    h4.sd-page__title.sd-title {
      display: none;
    }

    h5 {
      text-align: center;
      color: var(--ion-text-color);
      .sv-string-viewer {
        font-size: 20px;
        line-height: 28px;
        font-weight: 550;
      }
    }

    .sd-description {
      font-size: 14px;
      color: var(--ion-dark-blue);
      display: block;
      background: url('data:image/svg+xml;charset=UTF-8,<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9.50027" cy="9.72" r="6.97" stroke="rgb(17, 80, 153)" stroke-width="1.5"/><line x1="9.5" y1="8.5" x2="9.5" y2="13" stroke="rgb(17, 80, 153)" stroke-width="1.5" stroke-linecap="round"/><circle cx="9.5" cy="6" r="0.75" fill="rgb(17, 80, 153)"/></svg>');
      background-size: 16px 16px;
      background-position: 10px 19px;
      background-repeat: no-repeat;
      padding: 16px 16px 16px 32px;
      margin: 16px;
      background-color: #F7F7F7;
      border-radius: 8px;

      .sv-string-viewer {
        font-size: 16px;
        line-height: 24px;
        font-weight: 450;
      }
    }
  }
  .sd-multipletext {
    &__content{
      margin-bottom: 24px;
    }

    &__cell {
      padding-top: 0;
      padding-bottom: 24px;

      input {
        color: var(--ion-text-color);
        background: #fff;
      }

      .sd-multipletext__item-container {
        background: #fff;
        box-shadow: 0 0 0 0;
        border: 1px solid var(--ion-survey-input-bg);
        border-radius: 4px;
      }
    }
  }

  /** Code to center the survey image starts here **/
  .sd-image__image {
    visibility: hidden;
    margin-top: 8%;
  }
  /** Code to center the survey image ends here **/

  /** Code for button starts here **/
  .sd-btn {
    color: white;
    background: var(--ion-color-primary);
  }

  .sd-btn:not(:disabled):hover {
    box-shadow: 0 0 0 0 !important;
  }

  .sd-btn:not(:disabled):hover,
  .sd-btn:not(:disabled):focus {
    box-shadow: 0 0 0 2px var(--primary, var(--ion-color-primary));
  }
  /** Code for button ends here **/

  /** Code for image starts here **/
  .sd-image {
    text-align: center;
    .sd-image__image {
      display: inline-block;
    }
  }
  /** Code for image ends here **/

  /** Code for toggle button starts here **/
  .sv_qbln {
    width: auto;
  }

  .sd-boolean {
    background: var(--ion-color-secondary);
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sd-boolean__control:focus ~ .sd-boolean__switch {
    box-shadow: inset 0 0 0 2px var(--primary, var(--ion-color-primary));
  }

  .sd-boolean__thumb {
    color: var(--ion-color-light);
    background: var(--ion-color-primary);
  }
  /** Code for radio button ends here **/

  /** Code for file upload drag area starts here **/
  .sd-file__drag-area {
    background: var(--ion-color-light);
    border-radius: 8px;
    height: 90% !important;

    label {
      color: var(--ion-color-primary);
      background: var(--ion-color-light);
    }

    .sd-file__choose-btn--text {
      background: var(--ion-color-secondary);
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.5px;
      margin-top: 24px;
    }

    .sd-action:not(.sd-action--pressed):hover,
    .sd-action:not(.sd-action--pressed):focus {
      background: var(--ion-color-primary);
      color: var(--ion-color-light);
    }

    .sd-context-btn:hover use,
    .sd-context-btn:focus use {
      fill: var(--ion-color-primary);
    }

    .sd-file__decorator {
      border-radius: 8px !important;
      border: 1px dashed var(--border, var(--ion-main-bg-dark-blue));
      .sd-file__drag-area-placeholder {
        color: var(--ion-dark-blue);
      }
    }

    .sd-file__list {
      border-radius: 8px !important;
      border: 1px solid var(--border, var(--ion-main-bg-dark-blue));
    }
  }
  /** Code for file upload drag area starts here **/

  /** Code for radio button starts here **/
  .sd-multipletext__item-container.sd-input:focus-within {
    box-shadow: inset 0 0 0 2px var(--primary, var(--ion-color-primary));
  }

  .sd-item--checked .sd-item__decorator {
    background: var(--ion-color-primary);
  }

  .sd-radio--checked .sd-radio__control:focus + .sd-radio__decorator:after {
    background: var(--ion-color-light);
  }

  .sd-item--checked .sd-item__decorator {
    background: var(--ion-color-primary);
  }

  .sd-item--checked.sd-radio.sd-radio--checked {
    .sd-item--disabled.sd-item--disabled .sd-item__decorator,
    .sd-item__decorator {
      background: var(--ion-color-primary);
      border: none;
    }
  }

  .sd-radio {
    .sd-item--disabled.sd-item--disabled .sd-item__decorator,
    .sd-item__decorator {
      background: white;
      box-shadow: inset 0 0 0 0 var(--primary, var(--ion-color-light));
      border: 1px #d4d4d5 solid;
    }
  }

  .sd-checkbox--checked .sd-checkbox__control:focus + .sd-checkbox__decorator .sd-checkbox__svg use {
    fill: var(--ion-color-primary);
  }

  .sd-item__control:focus + .sd-item__decorator,
  .sd-item--allowhover .sd-selectbase__label:hover .sd-item__decorator {
    box-shadow: 0 0 0 2px var(--primary, var(--ion-color-primary));
    outline: none;
  }

  .sd-multipletext__item {
    .sd-text.sd-input {
      border: none;
    }
  }

  .sd-text.sd-input {
    background: var(--ion-color-light);
    border-radius: 4px;
    border: 1px solid var(--ion-survey-input-bg);
    color: var(--ion-color-text);
    box-shadow: 0 0 0 0px var(--primary, var(--ion-color-light));
  }

  .sd-multipletext__item {
    .sd-text.sd-input:focus {
      border: none;
    }
  }

  .sd-text.sd-input:focus {
    box-shadow: 0 0 0 0px var(--primary, var(--ion-color-light));
    border: 2px solid var(--ion-color-primary);
  }

  .sd-item__control-label {
    color: var(--ion-survey-light-grey);
    font-weight: 450;
  }
  /** Code for radio button ends here **/

  /** Code for Matrix Input starts here **/
  .sd-action.sd-action--negative.sd-matrixdynamic__btn.sd-matrixdynamic__remove-btn {
    .sv-string-viewer {
      color: #cc0000;
      font-size: 14px;
    }
  }

  .sd-action.sd-matrixdynamic__add-btn.sd-matrixdynamic__btn {
    .sv-string-viewer {
      color: var(--ion-color-primary);
      font-size: 14px;
    }
  }

  .sd-action:not(.sd-action--pressed):hover,
  .sd-action:not(.sd-action--pressed):focus {
    background-color: var(--ion-color-light) !important;
  }

  .sd-table:not(.sd-matrix__table) tr::after {
    height: 0px !important;
  }

  .sd-table__row {
    border-bottom: 1px solid var(--ion-color-disabled);
  }

  .sd-table__row:last-child {
    border-bottom: none;
  }

  .sd-matrixdynamic__footer {
    margin-top: -32px;
    position: relative;
  }

  /** Code for matrix old changes starts here **/
  .sd-element__header {
    padding-bottom: 32px;
  }

  .sd-element--complex > .sd-element__header--location-top:after {
    height: 0;
  }

  tr.sd-table__row {
    border-bottom: none !important;

    td.sd-table__cell {
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding-bottom: 5px;
      margin: 0 5px;
      color: #70757a;
      margin-bottom: 20px;

      sd-table__cell:nth-of-type(2) {
        margin-top: 5px;
      }

      &--error {
        border: none;
        margin-bottom: 0;

        &-top {
          padding: 0 0 8px;
        }
      }

      &-action--remove-row {
        border: none;
      }

      .sd-table__question-wrapper {
        margin-top: -8px;
        margin-left: 12px;
      }
    }

    td.sd-table__cell:focus-within {
      border: 1px solid var(--ion-color-primary);
    }


    td.sd-table__cell::before {
      padding-top: 8px !important;
      padding-bottom: 0px !important;
      padding-left: 12px;
      font-weight: 650 !important;
    }

    .sd-text.sd-input {
      border: none;
      padding: 0;
      color: #132a4e;
      height: 35px;
    }
  }
  /** Code for Matrix Input ends here **/

  .sd-body__navigation.sd-action-bar {
    display: none;
    }
  /** Code for survey js default navigation button starts here **/

}

.web-mode {
  .onboarding-survey-completed-block {
    .onboarding-survey-completed-text-block {
      ul {
        width: 85% !important;
      }
    }
  }
}
/* SURVEY ENDS HERE */

/* PADDING CLASSES STARTS HERE */
.add-1em-padding {
  padding: 1em;
}

.add-1em-top-padding {
  padding-top: 1em;
}

.add-1em-bottom-padding {
  padding-bottom: 1em;
}

.case-type-icon {
  color: $primary-text-color;
  width: 46px;
  display: flex;
  height: 46px;

  svg {
    display: flex;
    font-size: 24px !important;
    font-weight: normal;
    padding: 11px 8px;
    background: #f5f9fd;
    border-radius: 8px;
  }
}
/* PADDING CLASSES ENDS HERE */

.notification::after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: #cc0000;
  border: 1px solid #fff;
  top: 4px;
  right: -1px;
  border-radius: 50%;
}

/* SKELETON CLASSES STARTS HERE */
.client-case-selection-wrapper {
  ion-skeleton-text {
    --border-radius: 3px;
  }
}
/* SKELETON CLASSES ENDS HERE */

.toast-service {
  text-align: center;
  --border-radius: 8px;
}

/* FORM CLASSES STARTS HERE */
.form-input-item-box {
  border: 1px solid var(--ion-input-border-color);
  padding-bottom: 2px;
  margin-bottom: 16px;
  border-radius: 4px;
  .input-wrapper {
    margin-top: -4px;
  }
}

.form-input-box {
  --background: white;
  --padding-top: 0;
}

.date-time-input-box {
  min-width: 100%;
  max-width: 100%;
}

.mm-form-group {
  margin-right: 16px;
  margin-left: 16px;
}

.mm-form-group {
  ion-input {
    .label-text {
      font-family: 'Open Sans', sans-serif;
      font-weight: 650;
      color: var(--ion-light-dark-grey);
    }
  }

  ion-input.ng-invalid.ng-touched {
    .label-text {
      color: var(--ion-color-danger);
    }
  }
}

.item-interactive.ion-invalid {
  --highlight-background: white;
}
/* FORM CLASSES ENDS HERE */

/* IONIC ELEMENT CLASSES STARTS HERE */
ion-grid.info-grid {
  margin: 24px;

  ion-row.info-row {
    padding-bottom: 16px;
  }

  ion-col.icon-col {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ion-color-primary);
  }

  ion-col.info-col {
    .field-name {
      color: var(--ion-color-primary);
      font-size: 0.8rem;
      font-weight: 650;
    }

    .field-value {
      color: var(--ion-color-medium);
      font-size: 1rem;
    }
  }
}

ion-segment.slection-segment {
  margin: 16px;
  --background: #f5f9fd;
  border-radius: 24px;
  padding: 5px;
  ion-segment-button {
    --color: #70757a;
    --color-checked: white;
    --border-radius: 16px;
    --indicator-color: #115099;
    --padding-bottom: 5px;
    --padding-top: 5px;
    --border-color: none;
    height: 30px;
  }
}

ion-toolbar.header-toolbar {
  --background: var(--ion-color-light);
  --border-color: var(--ion-color-light);

  ion-title {
    color: var(--ion-text-color);
  }

  ion-back-button {
    color: var(--ion-text-color);
  }

  ion-icon {
    color: var(--ion-text-color);
  }

  ion-back-button {
    padding-left: 5px;
  }
}

ion-row.row-nav-block {
  width: 100%;
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;

  ion-col.icon-col {
    height: inherit;
    display: flex;
    align-items: center;

    .icon-box {
      width: 32px;
      height: 32px;
      background: var(--ion-lighter-dark-blue);
      border-radius: 50%;
      padding-top: 3%;
      text-align: center;

      .icon {
        font-size: 1.1rem;
        color: var(--ion-color-primary);
      }
    }

    .icon-box-skeleton {
      width: 32px;
      height: 32px;
      background: var(--ion-color-disabled);
      border-radius: 50%;
      padding-top: 3%;
      text-align: center;
    }
  }

  ion-col.info-col {
    display: flex;
    align-items: center;
    .label-dr-name {
      font-size: 14px;
      font-weight: 650;
      color: var(--ion-color-primary);
    }
  }

  ion-col.icon-left-col {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.bottom-border {
  border-bottom: 1px solid #ccc;
}

.page-header-icon-box {
  width: 81px;
  height: 81px;
  background: var(--ion-color-secondary);
  border-radius: 50%;
  padding-top: 3%;

  .page-header-icon {
    font-size: 2rem;
    color: var(--ion-color-primary);
  }
}

.page-header-icon-box-light {
  width: 81px;
  height: 81px;
  background: var(--ion-color-light);
  border-radius: 50%;
  padding-top: 3%;
  border: 4px solid var(--ion-color-primary);
  overflow: hidden;

  .page-header-icon {
    font-size: 3rem;
    color: var(--ion-color-primary);
  }
}

.header-icon-large {
  font-size: 5rem;
}

.primary-color {
  color: var(--ion-color-primary);
}
/* IONIC ELEMENT CLASSES ENDS HERE */

/* FIND PROVIDER SECTION CLASSES STARTS HERE */
ion-content.find-provider-pages,
ion-content.treatment-pages {
  .page-header-large-heading-row {
    padding-top: 16px;
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
  }

  .page-header-normal-heading-row {
    padding-top: 4px;
    font-size: 1rem;
    font-weight: 650;
    text-align: center;
    color: var(--ion-color-primary);
  }

  .page-header-large-para-row {
    padding-top: 10px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 16px;
    font-size: 1em;
    font-weight: 450;
    text-align: center;
    color: var(--ion-survey-light-grey);
  }

  .page-header-icon-row {
    display: flex;
    width: 100%;
    text-align: center;
    font-size: 42px;
    padding-top: 16px;
    justify-content: center;
  }

  .page-header-heading-row {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .page-header-text-row {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .textarea-row {
    padding: 16px;

    ion-textarea {
      border: 1px solid #ccc;
      height: 200px;
    }
  }

  .textarea-row-with-border {
    padding: 16px;

    ion-textarea {
      border: 1px solid #ccc;
      height: 200px;
    }

    ion-textarea.custom-textarea-with-border {
      border: 1px solid #ccc;
      height: 200px;
      --border-width: 0px;
      border-radius: 4px;

      .textarea-wrapper {
        width: 96% !important;
        height: 98% !important;
        margin-left: 12px;

        .label-text-wrapper {
          margin-top: -12px;
          font-size: 16px;
          font-family: 'open sans';
          font-weight: 650;
          color: #70757a;
        }
      }

      .textarea-bottom {
        padding-top: 12px;
      }

      textarea {
        height: 190px;
      }
    }

    ion-textarea.custom-textarea-with-border.has-focus {
      .textarea-wrapper {
        .label-text-wrapper {
          margin-top: -6px;
          font-size: 14px;
        }
      }
    }

    ion-textarea.custom-textarea-with-border.ion-touched {
      .textarea-wrapper {
        .label-text-wrapper {
          margin-top: -6px;
          font-size: 14px;
        }
      }
    }
  }

  .page-header-information-block {
    display: block;
    margin-top: 10px;
    margin-bottom: 24px;
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 4px;
    background: var(--ion-color-disabled);

    ion-icon {
      padding-top: 3px;
      padding-left: 3px;
    }

    .text {
      font-size: 14px;
      padding-right: 5px;
      padding-bottom: 5px;
    }
  }

  .date-selection-options {
    padding-top: 0.75rem;

    .date-header {
      font-size: 16px;
      font-weight: 650;
      color: var(--ion-color-primary-shade);
    }

    .time-slots-item {
      border: 1px solid #ccc;
      margin: 0.75rem 1rem;
      border-radius: 4px;
      text-align: center;

      .time-slot {
        font-family: 'Open Sans';
        font-size: 0.875rem;
        font-weight: 450;
        color: #132a4e;
      }
    }

    .time-slot-selected {
      border: 2px solid var(--ion-color-primary);
      --background: #f5f9fd;
      overflow: visible;

      .time-slot {
        font-weight: 550;
        color: #115099;
      }

      .selection-notification {
        visibility: visible;
      }

      .selection-notification-active {
        visibility: visible;
      }
    }
  }

  .onboarding-step-row {
    margin-bottom: 16px;

    .onboarding-step-check-mark {
      display: flex;
      align-items: center;
    }

    .onboarding-step-info {
      background: var(--ion-color-secondary);
      border-radius: 8px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .onboarding-current-step-info {
      background: linear-gradient(0.25turn, white, 50%, #bbd3f9);
      border-radius: 8px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .onboarding-step-text {
      padding-right: 10px;
    }

    .onboarding-step-icon {
      background: var(--ion-lighter-dark-blue);
      border-radius: 5px;
      display: flex;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
/* FIND PROVIDER SECTION CLASSES ENDS HERE */

/* HEALTH SECTION CLASSES STARTS HERE */
.header-nav-block {
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;

  ion-col.icon-col {
    display: flex;
    align-items: center;

    .icon-small {
      font-size: 1.3rem;
      color: var(--ion-color-primary);
    }

    .icon-medium {
      font-size: 1.5rem;
      color: var(--ion-color-primary);
    }

    .icon-box-light {
      width: 32px;
      height: 32px;
      background: var(--ion-color-light);
      border-radius: 50%;
      padding-top: 6%;
      text-align: center;

      .icon {
        font-size: 1.2rem;
        color: var(--ion-color-primary);
      }
    }
  }

  ion-col.info-col {
    .heading {
      font-size: 0.9rem;
      font-weight: 650;
      color: var(--ion-color-primary);
    }

    .sub-text {
      font-size: 0.8rem;
      color: var(--ion-text-color);
    }
  }
}

.header-notification-block {
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;

  ion-row {
    width: 100%;

    ion-col.icon-col {
      display: flex;
      align-items: center;

      .icon-small {
        font-size: 1.3rem;
        color: var(--ion-color-primary);
      }

      .icon-medium {
        font-size: 1.5rem;
        color: var(--ion-color-primary);
      }

      .icon-box-light {
        width: 32px;
        height: 32px;
        background: var(--ion-color-light);
        border-radius: 50%;
        padding-top: 6%;
        text-align: center;

        .icon {
          font-size: 1.2rem;
          color: var(--ion-color-primary);
        }
      }
    }

    ion-col.info-col {
      display: flex;
      align-items: center;

      .heading {
        font-size: 1rem;
        font-weight: 650;
        color: var(--ion-color-primary);
      }

      .sub-text {
        font-size: 0.9rem;
        color: var(--ion-text-color);
      }
    }
  }
}

.background-blue {
  background: var(--ion-extra-light-blue);
}

.background-yellow {
  background: var(--ion-notification-light-yellow);
}
/* HEALTH SECTION CLASSES ENDS HERE */

/* SKELETON CLASSES STARTS HERE */
.skeleton-color {
  background: var(--ion-color-disabled) !important;
}

.small-icon-skeleton {
  font-size: 1.5rem;
  color: var(--ion-color-disabled);
}

.extra-small-icon-skeleton {
  font-size: 1rem;
  color: var(--ion-color-disabled);
}
/* SKELETON CLASSES ENDS HERE */

/* TEXT CLASSES STARTS HERE */
.text-underline {
  text-decoration: underline;
}

.text-light-dark-grey {
  color: var(--ion-light-dark-grey);
}

.bold-text {
  font-weight: bold;
}

.phone-number {
  color: var(--ion-color-primary);
}
/* TEXT CLASSES ENDS HERE */

.scanbot-shutter-button {
  top: 2rem !important; // gross that we have to add the overriding style here, as well as add the !important, but it's due to scanbot
}
