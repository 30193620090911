@import "src/theme/variables";

.action-sheet-wrapper {
  display: block;
  text-align: center;
  margin: 0;

  /* header */
  #resource-list-header {

    &:first-child {
      color: $primary-text-color;
      font-size: 1em;
      font-weight: 650;

      ion-button {
        position: relative;
        right: 15px;
        top: -6px;

        ion-icon {
          padding-left: 3px;
          color: $primary-text-color;
          font-size: 20px;
        }
      }
    }
  }

  ion-content{
    height: 90vh;
  }

  #resource-list {
    overflow: auto;
    padding: 0 1.5em;

    & > ion-label {
      border-bottom: $text-input-border;
      height: 58px;
      justify-content: space-between;
      transition: all 300ms ease-in-out;


      &:last-child {
        border-bottom: none;
      }

      ion-text {
        transition: all 300ms ease-in-out;
      }

      ion-icon {
        transition: all 300ms ease-in-out;
        visibility: hidden;
        right: 24px;
      }

      &.selected {
        ion-text {
          font-weight: 550;
          font-size: 16px;
          line-height: 160%;
          color: $primary-text-color;
        }

        ion-icon {
          width: 25px;
          height: 25px;
          color: $primary-text-color;
          visibility: visible;
        }
      }
    }

    ion-label {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0;

      ion-text {
        font-weight: 550;
        font-size: 16px;
        line-height: 160%;
        color: #70757a;
        text-align: left;

        &.resource-copy {
          color: #8b8b8b;
        }
      }
    }

    &#resource-not-found ion-text {
      color: #8b8b8b;
      font-weight: 700;
    }
  }
}

/* loading skeleton text */
#loading {
  width: calc(100% - 3em);
  margin-bottom: 16px;

  & > div {
    height: 58px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: $text-input-border;
    justify-content: center;

    &.first {
      border-top: $text-input-border;
    }

    &:nth-child(odd) {
      ion-skeleton-text {
        width: 78%;
      }
    }

    ion-skeleton-text {
      width: 50%;
      height: 16px;
      margin: 10px 0;
      left: 0;
    }
  }
}
