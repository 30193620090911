// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #115099;
  --ion-color-primary-rgb: 17, 80, 153;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0d3b70;
  --ion-color-primary-tint: #f1f1f1;

  /** secondary **/
  --ion-color-secondary: #f5f9fd;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #115099;
  --ion-color-secondary-contrast-rgb: 17, 80, 153;
  --ion-color-secondary-shade: #c5dcee;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary - GHOST **/
  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 255, 255, 255;
  --ion-color-tertiary-contrast: #115099;
  --ion-color-tertiary-contrast-rgb: 17, 80, 153;
  --ion-color-tertiary-shade: #ffffff;
  --ion-color-tertiary-tint: #ffffff;

  /** success **/
  --ion-color-success: #0c8811;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #f9a608;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #cc0000;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** disabled **/
  --ion-color-disabled: #e1e1e1;
  --ion-color-disabled-rgb: 225, 225, 225;
  --ion-color-disabled-contrast: #132a4e;
  --ion-color-disabled-contrast-rgb: 19, 42, 78;
  --ion-color-disabled-shade: #c6c6c6;
  --ion-color-disabled-tint: #e4e4e4;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #f5f6f9;
  --ion-color-light-tint: #f5f6f9;

  --ion-safe-area-bottom: 0px;
  --ion-safe-area-right: 0px;
  --ion-safe-area-left: 0px;

  --ion-text-color: #132a4e;
  --ion-text-color-rgb: 19, 42, 78;
  --ion-font-family: 'Open Sans';

  /** Added by Muhammad
    * Used success danger and warning but those were light colors
    * So i added these as i was not sure if those are used at other places
  **/
  --ion-text-dark-green: #0c8811;
  --ion-text-dark-red: #cc0000;
  --ion-text-dark-yellow: #b05f00; //f9a608

  // Used for pages gradient background
  --ion-main-bg-dark-blue: #115099;
  --ion-main-bg-light-blue: #88bce5;
  --ion-extra-light-blue: #ebf2fc;
  --ion-lighter-dark-blue: #eaf2fc;
  --ion-dark-blue: #132a4e;

  --ion-notification-light-yellow: #fff2cc;
  --ion-notification-light-blue: #ebf2fc;
  --ion-notification-light-green: #cdecdf;

  // Survey colors
  --ion-survey-input-bg: #d4d4d5;
  --ion-survey-light-grey: #70757a;
  --ion-survey-description-text: #132a4e;
  --ion-survey-footer-bg: #eaeff5;

  // Form colors
  --ion-input-border-color: #d4d4d5;

  // Colors
  --ion-light-grey: #f7f7f7;
  --ion-light-dark-grey: #70757a;
  --ion-extra-light-grey: #f5f5f5; // This color is lighter than disabled and can be used as background for disabled elements
}

@media (prefers-color-scheme: light) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios ion-modal {
    --ion-background-color: var(--ion-background-color-step-100);
    --ion-toolbar-background: var(--ion-background-color-step-150);
    --ion-toolbar-border-color: var(--ion-background-color-step-250);
  }
}

// ******************************
// TEXT/COPY
// ******************************
$primary-text-color: #115099;
$secondary-text-color: #5c5a5a;
$tertiary-text-color: #132a4e;
$quaternary-text-color: #70757a;

// ******************************
// BUTTONS
// ******************************
$submit-button-bg: #115099;
$submit-button-disabled-bg: #f7f7f7;
$submit-button-disabled-color: #999999;

$primary-button-bg: #115099;
$primary-button-color: #ffffff;

$secondary-button-bg: #e6eaef;
$secondary-button-color: #132a4e;

$tertiary-button-bg: #E6EFFB;
$tertiary-button-color: #132a4e;

$button-disabled-bg: #e9e9e9;
$button-disabled-color: #ffffff;

// ******************************
// TEXT INPUTS
// ******************************
$text-input-border: 1px solid #d4d4d5;
$text-input-color: #99999a;
