@import "src/theme/variables";

.segment-toggle {
  display: flex;
  background: #f7f7f7;
  border: 0.5px solid #d4d4d5;
  border-radius: 4px;
  width: 117px;
  height: 28px;

  ion-segment-button {
    font-weight: 650;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    height: 24px;
    min-height: 24px;
    width: 54px;
    max-width: 54px;
    min-width: 54px;
    margin: 2px;
    --indicator-transition: all .200s ease-in-out;

    &.active {

      &::part(native),
      &.md::part(native) {
        color: #ffffff;
      }

      &::part(indicator),
      &.md::part(indicator) {
        --background-checked: $primary-text-color;
        background-color: $primary-text-color;
      }

      &::part(indicator-background),
      &.md::part(indicator-background) {
        --background-checked: $primary-text-color;
        background-color: $primary-text-color;
      }

    }

    &::part(indicator),
    &.md::part(indicator),
    &::part(indicator-background),
    &.md::part(indicator-background),
    &::part(native),
    &.md::part(native) {
      color: #70757a;
      border-radius: 3px;
      border-radius: 3px;
      padding-inline-start: 0;
      padding-inline-end: 0;
      display: flex;
      width: 54px;
      max-width: 54px;
      min-width: 54px;
      min-height: 24px;
    }

  }

}




