// .boxBorder {
//   border: 1px solid #D4D4D5;
//   border-radius: 4px;
//   &.invalid {
//       margin-bottom: 8px;
//       border: 1px solid #CC0000;
//   }
// }

:host {

}
.invalid {
  margin-bottom: 8px;
  border: 1px solid #CC0000;
}

mm-textarea {
  position: relative;
  top: 0;
  width: 100%;
  height: 140px;
  outline: none;

  div {
    width: 100%;
    height: 100%;
    border: 1px solid #d4d4d5;
    border-radius: 4px;
    font-size: 16px;
    padding: 16px !important;
    margin: 0;
    overflow: scroll;
    outline: none;
    color: transparent;

    &#content-with-styling {
      // layered (to the back) textarea with styling applied
      color: #0d0d0d;
      position: absolute;
      top: 0;
      z-index: -9;

      #overflow-text {
        background: rgba(220, 28, 28, 0.1) !important;
        font-size: 16px;
      }

      &.disabled {
        opacity: 0.3;
      }
    }

    &.is-populated {
      padding-top: 25px !important;
    }
  }

  #floating-label {
    position: absolute;
    top: 18px;
    display: block;
    width: calc(100% - 30px);
    left: 16px;
    text-transform: uppercase;
    font-size: 16px;
    transition: all .15s ease;
    background: #ffffff;
    color: #70757A;
    font-weight: 650;

    &.is-populated {
      top: 1px;
      font-size: 12px;
      padding-top: 5px;
    }
  }

  .item-inner {
    padding: 0;
  }
}

.required {
  // content: "⭑";
  position:relative;
  top: -5px;
  // left: -3px;
  // left: -5px;
  color: #cf3c4f;
  font-size: 10px;
}
