ion-app {
    &.web-mode {
        margin: auto;
        max-width: 480px;

        ion-icon {
            cursor: pointer;
        }

        ion-item {
            cursor: pointer;
        }

        a {
            cursor: pointer;
        }
    }
    // ion-content::part(scroll)::-webkit-scrollbar {
    //     display: none;
    // }
}
