@import 'src/theme/variables.scss';

@mixin primary {
  & fa-icon.icon-right {
    margin-inline-start: 8px;
  }

  & fa-icon.icon-left {
    padding-inline-end: 8px;
  }

  &::part(native){
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;
    height: 51px;
    --border-radius: 4px;
    background: $primary-button-bg;
    color: $primary-button-color;
    --color: $primary-button-color;
    opacity: 1;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 650;
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: uppercase;

    padding-inline: 24px;
    padding-block: 16px;

    &:disabled{
      background: #e9e9e9;
      --color: #fff;
      opacity: 1;
    }
  }
}

@mixin secondary {
  & fa-icon.icon-right {
    margin-inline-start: 8px;
  }

  & fa-icon.icon-left {
    padding-inline-end: 8px;
  }

  &.button-disabled{
    --background: #e9e9e9;
    --color: #fff;
    opacity: 1;
  }

  &::part(native){
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;
    height: 51px;
    --border-radius: 4px;
    color: $secondary-button-color;
    --color: $secondary-button-color;
    background: $secondary-button-bg;

    opacity: 1;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 650;
    line-height: normal;
    letter-spacing:0.28px;
    text-transform: uppercase;

    padding-inline: 24px;
    padding-block: 16px;
  }
}

@mixin tertiary {
  & fa-icon.icon-right {
    margin-inline-start: 8px;
  }

  & fa-icon.icon-left {
    padding-inline-end: 8px;
  }

  &.button-disabled {
    --background: #e9e9e9;
    --color: #fff;
    opacity: 1;
  }

  &::part(native) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;
    height: 51px;
    --border-radius: 4px;
    color: $tertiary-button-color;
    --color: $tertiary-button-color;
    background: $tertiary-button-bg;

    opacity: 1;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 650;
    line-height: normal;
    letter-spacing: 0.28px;
    text-transform: uppercase;

    padding-inline: 24px;
    padding-block: 16px;
  }
}

@mixin disabled {
  --background: #e9e9e9;
  --color: #fff;
  opacity: 1;
}
